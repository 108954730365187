// Calculate Color Setup Cost
const calculatColorSetupCost = (enquiry, colorSetupFee) => {
    const colorSetupIndex = enquiry?.meta
    ? Object.values(enquiry.meta).reduce(
        (acc, print) => acc + (print?.colorSetups || 0),
        0
    )
    : 0;
    const colorSetupCost = colorSetupIndex * colorSetupFee
    return colorSetupCost ?? 0
}

export { calculatColorSetupCost }