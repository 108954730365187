import React from "react"
import { Link } from "gatsby"

// Functions
import { numberWithCommas, floorValue } from "../../functions/number-formatter"
import { calculateCartAmount } from "../../functions/cart-amount"

// Context
import CartContext from '../../context/CartProvider';

export const CartSummary = ({ itemsObject, enquiry, correctionObject }) => {
    // Cart Context
    const cartContext = React.useContext(CartContext);
    const colorSetupFee = cartContext?.state?.colorSetupFee;
    const exVatAmount = cartContext?.state?.vatExclAmount

    const totalDiscountPercentage = enquiry?.discount?.percentage
    const discountCalculation = (1 - (totalDiscountPercentage / 100))

    const cartAmountObject = React.useMemo(() => {
        return calculateCartAmount(enquiry, colorSetupFee, exVatAmount)
    }, [enquiry, itemsObject])

    const OpenCorrection = (url) => url && window.open(url, '_blank')

    return <div id="priceSummary" style={{ background: "white" }}>
        <div className="row main"><b>Sammanställning</b><br /><i>Priset är ungefärligt och sätts efter godkänd korrektur.</i></div>
        <div className="row header"><span>Kundvagn</span></div>
        {Object.keys(itemsObject).map(key => {
            const item = itemsObject[key]
            return (<div key={item.uniqueId} className="item">
                <div className="row">
                    <div className="left">
                        <span>
                            <div className="row">
                                <span className="text">
                                    <Link to={item.url} title={`Gå till ${item.name}`}>{item.name}</Link>
                                    {item.sizes.map((size, i) => <div key={item.uniqueId + "_size_" + i} className="row light small">
                                        <span>
                                            <b>{size.size_name}</b> x {size.quantity}
                                        </span>
                                    </div>)}
                                </span>
                                <span className="price">
                                    <span>{numberWithCommas(floorValue(item.totalPrice * exVatAmount, 0))} SEK ex.moms</span>
                                    {totalDiscountPercentage ? <><br /><span className="discountedItemPrice">{numberWithCommas(floorValue((item.totalPrice * discountCalculation) * exVatAmount, 0))} SEK ex.moms</span></> : null}
                                </span>
                            </div>
                            {item.prints.map((print, i) => <div key={print.id + "_prints_" + i} className="row light small">
                                <span>
                                    <b>Tryck: </b>{print.name.replace("Tryck — ", "")}
                                </span>
                                {/* <span>
                                    <b>+ {numberWithCommas(floorValue((print.price) * exVatAmount, 0))} SEK ex.moms</b>
                                </span> */}
                            </div>)}
                            <span>
                                <b>Totalt: </b> {item.totalQuantity}
                            </span>
                        </span>
                        {/* <div className="row">
                            <span>Antal x {numberWithCommas(item.totalQuantity)}</span>
                            <span>= {totalAmount().toFixed(0)} ex. moms</span>
                        </div> */}
                    </div>
                    
                    {correctionObject && correctionObject[key] ? <><button className="correctionButton" onClick={() => OpenCorrection(correctionObject[key].url)}>Se korrektur</button></> : null}
                </div>
            </div>)
        })}
        <div className="row spacer"></div>
        <div className="row line"></div>
        <div className="row header">
            <span className="totalSummary">
                Tryckschabloner: <b>{`${numberWithCommas(floorValue(cartAmountObject.colorSetupCostExVat, 0))} SEK ex.moms`}</b><br />
                Frakt: <b>{`${numberWithCommas(floorValue(cartAmountObject.shippingCostExVat, 0))} SEK ex.moms`}</b><br />
                {cartAmountObject.shippingCostExVat > 0 ? <><i>{numberWithCommas(floorValue(cartAmountObject.shippingCostExVat, 0))} SEK vid order under 3000 SEK</i><br /></> : null}
                {cartAmountObject.totalDiscount ? <>Rabatt {totalDiscountPercentage}%: <b>-{numberWithCommas(floorValue(cartAmountObject.totalDiscount, 0))} SEK</b><br /></> : null}
                <br />Belopp ex. moms: <b>{numberWithCommas(floorValue(cartAmountObject.cartAmountExVat, 0))} SEK</b><br />
                Moms: <b>{numberWithCommas(floorValue(cartAmountObject.totalVat, 0))} SEK</b><br /><br />
                <span className="toPay">Att betala<br /><b>{numberWithCommas(floorValue(cartAmountObject.cartAmountWithDiscount, 0))} SEK ink. moms</b></span>
            </span>
        </div>
    </div>
}