import React, { createContext, useReducer, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'

const initialState = {
  categoryNav: []
};

const ConfigContext = createContext({ configData: initialState });

export const ConfigProvider = ({ children }) => {
  const [configData, configDataDispatch] = useReducer(configDataReducer, initialState)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const queryParameters = new URLSearchParams(globalHistory.location.search);
    const adminParameter = queryParameters.get("admin")
    const adminLocalStorage = localStorage.getItem("attitude-se-admin")
    if (adminParameter || adminLocalStorage) {
      configDataDispatch({ type: "admin" })
      localStorage.setItem("attitude-se-admin", true)
    }
  }, [])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 150);
      }
    })
  }, [])

  // Collections
  const { allBigCommerceCategories: { categories } } = useStaticQuery(graphql`
query {
  allBigCommerceCategories(
    sort: {sort_order: ASC}
    filter: {is_visible: {eq: true}}
    ) {
    categories: edges {
      category: node {
        id
        name
        page_title
        description
        image_url
        localImage {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        meta_description
        parent_id
        bigcommerce_id
        custom_url {
          url
        }
        is_visible
      }
    }
  }
}
`)

  useMemo(() => {
    const array = categories
    const isVisibleArray = array.filter(obj => obj.category.is_visible)
    const topLevelArray = isVisibleArray.filter(obj => obj.category.parent_id === 0)

    let jsxArray = []
    topLevelArray.forEach(obj => {
      const topArray = []
      const { category: { bigcommerce_id } } = obj
      const childrens = array?.filter(obj => { if (obj?.category?.parent_id === bigcommerce_id) return obj?.category })
      // Level 1
      if (childrens.length > 0) childrens.forEach((obj) => {
        const subArray = []
        const { category: { bigcommerce_id } } = obj
        const extraCategories = extraCategoriesObject[bigcommerce_id]
        const childrens = array?.filter(obj => { if (obj?.category?.parent_id === bigcommerce_id || extraCategories?.includes(obj?.category?.bigcommerce_id)) return obj?.category }).map(obj => obj.category)
        // Level 2
        if (childrens.length > 0) childrens.forEach((obj) => {
          const pushObject = obj
          pushObject.childrens = childrens
          subArray.push(pushObject)
        })
        // Build it
        const pushObject = obj.category
        pushObject.childrens = childrens
        topArray.push(pushObject)
      })
      // Push all levels
      jsxArray = [...topArray]
    })
    if (jsxArray.length > 0) configDataDispatch({ type: "categoryNav", value: jsxArray })
    else return null
  }, [categories]);

  useMemo(() => {
    const array = []
    configData.categoryNav.forEach(obj => {
      if (obj.name && obj.custom_url.url) array.push({
        bigcommerce_id: obj.bigcommerce_id,
        name: obj.name,
        url: obj.custom_url.url,
        image: obj.image_url
      })
      if (obj.childrens.length > 0) obj.childrens.forEach(childObj => {
        if (childObj.name && childObj.custom_url.url) array.push({
          bigcommerce_id: childObj.bigcommerce_id,
          name: childObj.name,
          url: childObj.custom_url.url,
          image: childObj.image_url
        })
      })
    })
    if (array.length > 0) configDataDispatch({ type: "categoryArray", value: array })
    else return null
  }, [configData.categoryNav])

  const toggleLoader = (type) => {
    setLoader(type)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content = 'initial-scale=1';
      viewport.content = 'width=device-width';
    }
  }

  return (
    <ConfigContext.Provider
      value={{
        configData,
        toggleLoader,
        scrollToTop
      }}>
      {loader ? <div id="loader">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> : null}
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;

const configDataReducer = (state, action) => {
  switch (action.type) {
    case "categoryNav":
      state.categoryNav = action.value
      return { ...state }
    case "categoryArray":
      state.categoryArray = action.value
      return { ...state }
    case "admin":
      state.admin = true
      return { ...state }
    default:
      return { ...state };
  }
};

const extraCategoriesObject = {
  42: [47, 48, 137, 138, 68, 147, 149, 148, 44]
}