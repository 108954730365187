import { calculatColorSetupCost } from "./calculate-color-setup-cost"

const calculateCartAmount = (enquiry, colorSetupFee, exVatAmount) => {
    const quanity = enquiry?.cart?.quantity || 0
    const cartAmount = enquiry?.cart?.cartAmount || 0
    const shippingCost = enquiry?.cart?.shippingCost || 0
    const totalCartAmount = enquiry?.cart?.totalAmount || 0
    const totalDiscountPercentage = enquiry?.discount?.percentage || 0
    const calculateWithDiscount = (value) => totalDiscountPercentage ? value * (1 - (totalDiscountPercentage / 100)) : value
    const colorSetupCost = calculatColorSetupCost(enquiry, colorSetupFee)

    return {
        totalQuantity: quanity,
        cartAmount: totalCartAmount,
        cartAmountWithDiscount: calculateWithDiscount(totalCartAmount),
        cartAmountExVat: calculateWithDiscount(totalCartAmount) * exVatAmount,
        baseCost: cartAmount,
        colorSetupCost: colorSetupCost,
        colorSetupCostExVat: colorSetupCost * exVatAmount,
        totalVat: calculateWithDiscount(totalCartAmount) * 0.2,
        totalDiscount: totalCartAmount - calculateWithDiscount(totalCartAmount),
        shippingCost: shippingCost,
        shippingCostWithDiscount: calculateWithDiscount(shippingCost),
        shippingCostExVat: shippingCost * exVatAmount,
    }
}

export { calculateCartAmount }