import * as React from "react"
import { Link } from "gatsby"

import "../styles/top-banner.scss"
import Email from "../icons/email"
import Phone from "../icons/phone"
import Sweden from "../icons/sweden"

import CartContext from '../context/CartProvider';

export function TopBanner() {
    const cartContext = React.useContext(CartContext);
    const cartState = cartContext && cartContext?.state;
    const toggleVatStatus = cartContext && cartContext?.toggleVatStatus;
    const [vat, setVat] = React.useState(cartState?.vatIncluded)

    React.useEffect(() => {
        setVat(cartState?.vatIncluded)
    }, [cartState?.vatIncluded])

    const toggleVat = (value) => toggleVatStatus(value)

    return (
        <div id={"top-banner"}>
            <header>
                <div className={"box"}>
                    <a href="tel:+4631134141" rel="noopener"><Phone color="#303638" size="12" margin="10" />Låt oss hjälpa dig — 031 - 13 41 41</a>
                    <Link to="/personlig-service" title={"Gå till Personlig Service"} target="_blank" rel="noopener"><Email color="#303638" size="24" margin="4" />Kontakta oss</Link>
                    <p><Sweden size="14" margin="8" />Vi trycker kläder i Sverige</p>
                </div>
                <div className={"box"}>
                    <div className={"moms-wrapper"}>
                        <div className={"switcher"}>
                            <div className={vat ? "switcher-box active" : "switcher-box"} onClick={() => toggleVat(true)}>Inkl. moms</div>
                            <div className={!vat ? "switcher-box active" : "switcher-box"} onClick={() => toggleVat(false)}>Exkl. moms</div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}
