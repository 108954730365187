import React from 'react';
import { CartProvider } from './src/context/CartProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { ConfigProvider } from './src/context/ConfigProvider';

// Modules
import { Header } from './src/components/general/header';
import Layout from './src/components/general/layout';

// Styles
import "./src/styles/global.scss";
import "./src/styles/variables.scss";
import "./src/styles/buttons.scss";

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This site has been updated. Reload to display the latest version?`
//   );
//   if (answer === true) {
//     window.location.reload();
//   }
// };

const wrapRootElement = ({ element }) => (
  <PriceProvider>
    <CartProvider>
      <ConfigProvider>
        <Layout>
          <Header />
          {element}
        </Layout>
      </ConfigProvider>
    </CartProvider>
  </PriceProvider>
);

export { wrapRootElement };
