import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Function
import { validateFile } from '../functions/validate-file';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBhm2f4M8femRxSs8rgkHnYTXWJSTZvCpg",
    authDomain: "attitude-firestore.firebaseapp.com",
    projectId: "attitude-firestore",
    storageBucket: "attitude-cart-files",
    messagingSenderId: "287803462284",
    appId: "1:287803462284:web:3789c28fbc2d91473961a9"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

// Get a list of cities from your database
export default function getEnquiries(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const enquiryRef = doc(db, "enquiry", id);
            const enquirySnap = await getDoc(enquiryRef);
            if (enquirySnap.exists()) {
                resolve(enquirySnap.data())
            } else {
                reject("No enquiry found")
            }
        }
        catch (err) { reject(err) }
    })
}

const uploadCartFile = async (file, printId, fileIndex) => {
    return new Promise((resolve, reject) => {
        try {
            // Validate the file
            try {
                validateFile(file); // Call validateFile to check type and size
            } catch (validationError) {
                return reject(validationError.message); // Reject if validation fails
            }

            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: file.type,
                customMetadata: {
                    printId: printId,
                    timestamp: new Date().toString()
                }
            };

            // Create the file name
            const fileSuffix = file.name.split('.').pop()
            const fileName = `${printId}_file_${fileIndex}.${fileSuffix}`;
            const storageRef = ref(storage, `${fileName}`);

            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    switch (error.code) {
                        case 'storage/unauthorized':
                            reject('User does not have access / Authentication problem');
                            break;
                        case 'storage/canceled':
                            reject('User cancelled upload');
                            break;
                        case 'storage/unknown':
                            reject('Unknown upload problem');
                            break;
                        default:
                            break;
                    }
                },
                () => {
                    const fileType = fileName?.split('.')?.pop() ?? 'unknown'
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve({ name: fileName, type: fileType, size: file.size, url: downloadURL });
                    });
                }
            );
        } catch (err) {
            console.error(err);
            reject('An error occurred during the upload process.');
        }
    });
};

export { db, uploadCartFile }