import * as React from "react"
import { Link } from 'gatsby'

// Styles
import "../../styles/breadcrumb.scss"

const BreadCrumb = ({ location, breadCrumbArray = [], crumbLabel }) => {
    if (!crumbLabel || !location) return null
    const breadCrumbs = breadCrumbArray.map(obj => ({
        title: `${obj.name} med tryck`,
        src: obj.url
    }))
    const array = [
        { title: "Hem", src: "/" },
        ...breadCrumbs,
        { title: crumbLabel, src: location.pathname }
    ]
    return (
        <nav className="breadCrumbWrapper">
            <ul>
                {array.map((obj, i) => <li key={obj.title + i}>
                    <Link to={obj.src} itemProp="url" title={"Gå till " + obj.title}>
                        <span itemProp="title">{obj.title}</span>
                    </Link>
                    <span className="delimiter">{i < (array.length - 1) ? "/" : null}</span>
                </li>)}
            </ul>
        </nav>
    )
}

export default BreadCrumb
